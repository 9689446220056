import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useBaseStyles } from '../../common/styles';

const StyledTypography = styled(Typography)(({ theme }) => ({
    //the textTransform and fontSize properties only change via sx setting, instead of classes
    textTransform: 'capitalize',
    fontSize: 13
}));


function DisplayPoints({ order }) {
    const baseClasses = useBaseStyles();
    const { points_status, points } = order;


    if (points_status === "guest") {
        return <StyledTypography variant="button" className={`${baseClasses.base} ${baseClasses.grey_bg}`}>
            {points}
        </StyledTypography>
    } else if (points_status === "initial" || points_status === "pending") {
        return <StyledTypography variant="button" className={`${baseClasses.base} ${baseClasses.lightBlue_bg}`}>
            {points}
        </StyledTypography>

    } else if (points_status === "posted") {
        return <StyledTypography variant="button" className={`${baseClasses.base} ${baseClasses.green_bg}`}>
            {points}
        </StyledTypography>
    } else if (points_status === "partially posted") {
        return <StyledTypography variant="button" className={`${baseClasses.base} ${baseClasses.teal_bg}`}>
            {points}
        </StyledTypography>
    } else if (points_status === "reset") {
        return <StyledTypography variant="button" className={`${baseClasses.base} ${baseClasses.amber_bg}`} >
            {points}
        </StyledTypography>

    }
}



export {
    StyledTypography, DisplayPoints
}
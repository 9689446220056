import React, { useState, useEffect } from 'react';
import { Paper, Box, Typography, Divider, Grid, TextField } from '@mui/material';
import { ActionButton } from "../../common/components/common/inputs";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Checkbox, FormGroup, FormControlLabel, FormControl } from '@mui/material';
import useAxiosPrivate from "../../common/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../common/hooks/useAppContext';
import { RewardSettingService } from "../../services/RewardSettingService";
import { Notification } from '../../common/components/common';
import notifyError from '../../common/errors/notifyError';
import { disabledRoles } from '../../common/data';
import { useStyles } from './styles';
import { useBaseStyles } from '../../common/styles';
import { RadioGroup } from '../../common/components/common/inputs';
import { pink } from '@mui/material/colors';
import { styled } from '@mui/material/styles';


const delayItems = [
    { id: 1, value: 7, label: '7 days' },
    { id: 2, value: 14, label: '14 days' },
    { id: 3, value: 30, label: '30 days' },
]

const expiringItems = [
    { id: 1, value: 4, label: '90 days' },
    { id: 2, value: 5, label: '180 days' },
    { id: 3, value: 1, label: '1 year' },
    { id: 4, value: 2, label: '2 years' },
    { id: 5, value: 3, label: '3 years' },
    { id: 6, value: 0, label: 'Never expired' },
]

const thresholdItems = [
    { id: 1, value: 100, label: '100' },
    { id: 2, value: 500, label: '500' },
    { id: 3, value: 1000, label: '1000' },
    { id: 4, value: 0, label: 'No Threshold' },
]

const discountItems = [
    { id: 1, value: 0.1, label: '10%' },
    { id: 2, value: 0.15, label: '15%' },
    { id: 3, value: 0.2, label: '20%' },
    { id: 4, value: 0.25, label: '25%' },
    { id: 5, value: 0.3, label: '30%' },
    { id: 6, value: 0.35, label: '35%' },
    { id: 7, value: 0.4, label: '40%' },
    { id: 8, value: 0.5, label: '50%' },
    { id: 9, value: 0.6, label: '60%' },
    { id: 10, value: 1, label: 'No Limit' }
]

//all 
// loyalty name
//to do : custom, set once, never change
// reminder: never change, if need to change, 
// free: delete app after install
// paid: contact to change
//merchant: store and user agreement, check out: opt in

//general:confirm


export default function General() {
    const classes = useStyles();
    const baseClasses = useBaseStyles();

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });

    const [programName, setProgramName] = useState();
    const [postPointsDelay_days, setPostPointDelay_days] = useState(30);
    const [email, setEmail] = useState({ isToNotifyPending: false, isToNotifyPosted: false });
    const [pointExpiring_years, setPointExpiring_years] = useState(2);
    const [isToEnableGuestEarning, setIsToEnableGuestEarning] = useState();
    const [redeemThreshold, setRedeemThreshold] = useState(0);
    const [discountPercentageLimit, setDiscountPercentageLimit] = useState(1);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        RewardSettingService.getConfiguration(axiosPrivate)
            .then(response => {
                const config = response.data.configuration;
                setProgramName(config.programName);
                setPostPointDelay_days(config.postPointsDelay.days);
                const { isToNotifyPending, isToNotifyPosted } = config;
                setEmail({ isToNotifyPending, isToNotifyPosted })
                setPointExpiring_years(config.pointExpiring_years);
                setIsToEnableGuestEarning(config.isToEnableGuestEarning);
                setRedeemThreshold(config.redeemThreshold);
                setDiscountPercentageLimit(config.discountPercentageLimit);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate, auth]);


    const handleProgramNameChange = e => {
        e.preventDefault();
        setProgramName(e.target.value);
    }

    const handleProgramNameUpdate = e => {
        if (!disabled) {
            RewardSettingService.updateProgramName(axiosPrivate, programName);
        }
        setDisabled(!disabled);
    }

    const handleDelayChange = e => {
        e.preventDefault();
        setPostPointDelay_days(e.target.value);
        RewardSettingService.updateDelay(axiosPrivate, e.target.value);
    }

    const handleExpiringChange = e => {
        e.preventDefault();
        setPointExpiring_years(e.target.value);
        RewardSettingService.updateExpiring(axiosPrivate, e.target.value);
    }

    const handleNotificationChange = e => {
        e.preventDefault();
        const name = e.target.name;
        const checked = e.target.checked;
        setEmail({ ...email, [name]: checked });
        RewardSettingService.updateEmailNotification(axiosPrivate, email);
    }

    const handleRedeemThresholdChange = e => {
        e.preventDefault();
        setRedeemThreshold(e.target.value);
        RewardSettingService.updateRedeemThreshold(axiosPrivate, e.target.value);
    }

    const handleGuestChange = e => {
        e.preventDefault();
        setIsToEnableGuestEarning(e.target.checked);
        RewardSettingService.updateGuestEarningEnabled(axiosPrivate, e.target.checked);
    }

    const handleDiscountChange = e => {
        e.preventDefault();
        setDiscountPercentageLimit(e.target.value);
        RewardSettingService.updateDiscountPercentageLimit(axiosPrivate, e.target.value);
    }


    const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
        //the textTransform and fontSize properties only change via sx setting, instead of classes
        color: pink[800],
        '&.Mui-checked': {
            color: pink[600],
        },
    }));

    return (
        <Box sx={{ margin: 2 }}>
            <Grid container alignItems="stretch" justify='space-around' spacing={2}>
                <Grid item xs={12} lg={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Loyalty Program
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography sx={{ width: '100%', fontSize: 12, mb: 2 }} className={baseClasses.secondary}>
                            Customize the name of the loyalty program
                        </Typography>
                        <Box display="flex">
                            <TextField
                                disabled={disabled}
                                variant="filled"
                                size="small"
                                value={programName}
                                onChange={handleProgramNameChange}
                                sx={{
                                    width: '50%'
                                }}
                            />
                            <ActionButton
                                color="primary"
                                disabled={disabledRoles.includes(auth.user.role)}
                                onClick={handleProgramNameUpdate}>
                                <EditOutlinedIcon fontSize="small" />
                            </ActionButton>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Point Posting
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography sx={{ width: '100%', fontSize: 12, mb: 2 }} className={baseClasses.secondary}>
                            Set the duration of points pending, after which points are posted to customer accounts.
                        </Typography>
                        <RadioGroup
                            name="delay"
                            value={postPointsDelay_days}
                            onChange={handleDelayChange}
                            items={delayItems}
                            disabled={disabledRoles.includes(auth.user.role)}
                        />
                    </Paper>
                </Grid>


                <Grid item xs={12} md={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Point Expiring
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography sx={{ width: '100%', fontSize: 12, mb: 2 }} className={baseClasses.secondary}>
                            Set the valid duration of points, after which points are expired.
                        </Typography>
                        <RadioGroup
                            name="pointExpiring_years"
                            value={pointExpiring_years}
                            onChange={handleExpiringChange}
                            items={expiringItems}
                            disabled={disabledRoles.includes(auth.user.role)}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Email
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography sx={{ width: '100%', fontSize: 12, mb: 2 }} className={baseClasses.secondary}>
                            Receive email notifications of different events for customers.
                        </Typography>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="Points pending"
                                    control={<StyledCheckbox
                                        name="isToNotifyPending"
                                        value={email.isToNotifyPending}
                                        checked={email.isToNotifyPending}
                                        onChange={handleNotificationChange}
                                        disabled={disabledRoles.includes(auth.user.role)}
                                    />}
                                    label="Points pending"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="Points posted to account"
                                    control={<StyledCheckbox
                                        name="isToNotifyPosted"
                                        value={email.isToNotifyPosted}
                                        checked={email.isToNotifyPosted}
                                        onChange={handleNotificationChange}
                                        disabled={disabledRoles.includes(auth.user.role)}
                                    />}
                                    label="Points posted to account"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </Paper>
                </Grid>


                <Grid item xs={12} md={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Guest Earning
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography sx={{ width: '100%', fontSize: 12, mb: 2 }} className={baseClasses.secondary}>
                            Allow guest checkout to earn points
                        </Typography>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="Guest Enabled"
                                    control={<StyledCheckbox
                                        name="isToEnableGuestEarning"
                                        value={isToEnableGuestEarning} // should add value, otherwise error
                                        checked={isToEnableGuestEarning}
                                        onChange={handleGuestChange}
                                        disabled={disabledRoles.includes(auth.user.role)}
                                    />}
                                    label="Enable guest earning"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </Paper>
                </Grid>


                <Grid item xs={12} md={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Redeem
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography sx={{ width: '100%', fontSize: 16 }}>
                            Redeem Value
                        </Typography>

                        <Typography sx={{ width: '100%', fontSize: 12, mb: 4 }} className={baseClasses.secondary}>
                            1 point can be redeemed for 0.01 of local currency.
                        </Typography>
                        <Typography sx={{ width: '100%', fontSize: 16 }}>
                            Redeem Threshold
                        </Typography>
                        <Typography sx={{ width: '100%', fontSize: 12, mb: 2 }} className={baseClasses.secondary}>
                            Set the mininum number of points, below which points cannot be redeemed
                        </Typography>
                        <RadioGroup
                            name="redeem_threshold"
                            value={redeemThreshold}
                            onChange={handleRedeemThresholdChange}
                            items={thresholdItems}
                            disabled={disabledRoles.includes(auth.user.role)}
                            sx={{
                                color: pink[800],
                                '&.Mui-checked': {
                                    color: pink[600],
                                },
                            }}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Disable Point Earning
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography sx={{ width: '100%', fontSize: 12, mb: 2 }} className={baseClasses.secondary}>
                            Disable point earning when discount percentage exceeds a certain limit
                        </Typography>
                        <RadioGroup
                            name="discountPercentageLimit"
                            value={discountPercentageLimit}
                            onChange={handleDiscountChange}
                            items={discountItems}
                            disabled={disabledRoles.includes(auth.user.role)}
                            sx={{
                                color: pink[800],
                                '&.Mui-checked': {
                                    color: pink[600],
                                },
                            }}
                        />
                    </Paper>
                </Grid>

            </Grid>

            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </Box >
    )
}
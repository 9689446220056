import React, { useEffect } from 'react';
import { Typography, Grid, Box, Divider } from '@mui/material';
import { useBaseStyles } from '../../common/styles';
import useAppContext from '../../common/hooks/useAppContext';
import { capitalize } from '../../common/utils/string';

function CustomerMenu({ customers, tiers, prevSelCustomer, selectedCustomer, updateSelectedCustomer }) {
    const baseClasses = useBaseStyles();
    const { store } = useAppContext();
    const abbrevStoreName = store?.abbrevStoreName;

    useEffect(() => {
        if (prevSelCustomer) // may not exist, because storeId or search condition changes in admin.spotfraud.app
            document.querySelector(`#customer_${prevSelCustomer._id}`)?.classList.remove('selected');
        if (selectedCustomer)
            document.querySelector(`#customer_${selectedCustomer._id}`).classList.add('selected');
    }, [selectedCustomer]);


    const handleClick = (e, newCustomer) => {
        e.preventDefault();
        if (selectedCustomer._id != newCustomer._id) {
            updateSelectedCustomer(newCustomer);
        }
    }
    return (
        <Box sx={{
            backgroundColor: 'white',
        }}>
            <Divider sx={{ mt: 2 }} />
            {customers.length === 0 ?
                <Typography>No Customers</Typography> :
                customers.map((customer, index) => (
                    <div key={index} >
                        <Grid container key={index} className='item' id={`customer_${customer._id}`}
                            sx={{ py: 1 }}
                            onClick={(e) => handleClick(e, customer)}
                        >
                            {/* <Grid item key={1} xs={4} className={baseClasses.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 9 }}>
                                    {customer.abbrevStoreName || abbrevStoreName}
                                </Typography>
                            </Grid> */}

                            <Grid item key={2} xs={4}>
                                <Typography sx={{ width: '100%', fontSize: 14 }}>
                                    {customer.first_name} {customer.last_name}
                                </Typography>
                            </Grid>

                            <Grid item key={3} xs={4} className={baseClasses.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 14 }}>
                                    {customer.currency_symbol}{customer.spending}
                                </Typography>
                            </Grid>

                            <Grid item key={4} xs={4} className={baseClasses.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 14 }}>
                                    {capitalize(tiers[customer.loyaltyTier].name)}
                                </Typography>
                            </Grid>


                        </Grid>
                        <Divider />
                    </div>
                ))
            }
        </Box >
    );
}

export default React.memo(CustomerMenu);


import React, { useState, useEffect } from "react";
import AppPagination from "../../common/components/common/AppPagination";
import CustomerMenu from "./CustomerMenu";
import RewardDetail from "./RewardDetail";
import { Box, CircularProgress } from '@mui/material';
import useAxiosPrivate from "../../common/hooks/useAxiosPrivate";
import {
    setQueryCustomerResult,
    getQueryCustomerEmail,
    getQueryCustomers
} from '../../common/browser/localStorage';
import { useNavigate, useLocation } from 'react-router-dom';
import useQuery from '../../common/hooks/useQuery';
import CustomerService from '../../services/CustomerService';
import { Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';

import notifyError from '../../common/errors/notifyError';
import { Notification } from '../../common/components/common';
import useAppContext from '../../common/hooks/useAppContext';
import { useBaseStyles } from "../../common/styles";

const useStyles = makeStyles(theme => ({
    select: {
        height: 19,
        width: 52,
        borderRadius: 5,
        fontSize: 13,
        borderColor: theme.palette.primary.light
    }
}))

const CustomerDisplay = () => {
    const classes = useStyles();
    const baseClasses = useBaseStyles();
    const [prevSelCustomer, setPrevSelCustomer] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [page, setPage] = useState(1);
    const [customers, setCustomers] = useState([]);
    const [tiers, setTiers] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(20); //customers per page
    const [count, setCount] = useState(0); // total number of all customers that is indexed by page and pageCount
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const searchQuery = useQuery();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [isLoading, setIsLoading] = useState(true);
    const { store } = useAppContext();

    const updateSelectedCustomer = (newCustomer) => {
        setPrevSelCustomer(selectedCustomer);
        setSelectedCustomer(newCustomer);
    }

    const clearSelectedCustomer = () => {
        setPrevSelCustomer(null);
        setSelectedCustomer(null);
    }

    const isUpdated = (response) => {
        if (response.data.count > 0) {
            return (!selectedCustomer  // empty to non-empty
                || count !== response.data.count //non-empty to non-empty
                || pageCount !== Math.ceil(response.data.count / pageSize) //pageSize changes, so pageCount changes
                || JSON.stringify(customers[0]) !== JSON.stringify(response.data.customers[0]))
        } else { // non-empty to empty order
            return selectedCustomer;
        }
    }

    const updateCommon = (response) => {
        setCustomers(response.data.customers);
        setCount(response.data.count);
        setPageCount(Math.ceil(response.data.count / pageSize));  // 400 items / 20 = 20
        setTiers(response.data.tiers);
        if (response.data.customers.length > 0) {
            updateSelectedCustomer(response.data.customers[0]);
        } else {
            clearSelectedCustomer();
        }
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const email = searchQuery.get('email');
        let filter = "";

        if (email) {
            if (email === getQueryCustomerEmail()) {
                const requestCustomers = getQueryCustomers();
                setPageCount(1); // only one page
                setCustomers(requestCustomers);
                if (requestCustomers?.length > 0) {
                    updateSelectedCustomer(requestCustomers[0]);
                }
                setIsLoading(false);
            } else {
                filter = `email=${email}`;
                CustomerService.searchCustomers(axiosPrivate, filter, page, pageSize)
                    .then(response => {
                        if (!isMounted) {
                            return;
                        }
                        if (isUpdated(response)) {
                            updateCommon(response);
                            setQueryCustomerResult(email, response.data.customers);
                        }
                        setIsLoading(false);
                    })
                    .catch(err => {
                        isMounted && setIsLoading(false);
                        notifyError(err, setNotify, navigate, location);
                    });
            }

        } else {
            CustomerService.getCustomers(axiosPrivate, page, pageSize)
                .then(response => {
                    if (!isMounted) {
                        return;
                    }

                    if (isUpdated(response)) {
                        updateCommon(response);
                    }
                    setIsLoading(false);
                })
                .catch(err => {
                    isMounted && setIsLoading(false);
                    notifyError(err, setNotify, navigate, location);
                });
        }

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, page, pageSize, location, navigate, store]);

    return (
        <>
            {isLoading ?
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}>
                    <CircularProgress sx={{
                        marginTop: 10,
                        marginBottom: 10,
                        mx: 'auto'
                    }} />
                </Box> :
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "scroll",
                    // justifyContent: "flex-end" //DO NOT USE THIS WITH 'scroll'
                }}>
                    <Box sx={{
                        minWidth: 250,
                        width: '28%',
                        borderRight: 1,
                        borderColor: '#D3D3D3',
                        overflowX: "scroll",
                        overflowY: "scroll",
                        display: "flex",
                        flexDirection: "column",
                        mx: 2
                    }} > {/*cannot add divider within grid*/}
                        <Box display="flex" sx={{ mt: 1.5, alignItems: "center" }}>
                            <Typography sx={{ fontSize: 16 }} align="left">Customers</Typography>
                            <Box display="flex" sx={{ alignItems: "center", ml: 2, mr: 4 }} className={baseClasses.secondary}>
                                <Typography sx={{ fontSize: 11, mr: 1 }} align="left">Views</Typography>
                                <select
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(e.target.value);
                                    }}
                                    className={classes.select}
                                >
                                    <option>10</option>
                                    <option>20</option>
                                    <option>30</option>
                                    <option>50</option>
                                    <option>100</option>
                                </select>
                                <Typography sx={{ fontSize: 11, ml: 1 }} align="left">Per Page</Typography>
                            </Box>
                        </Box>
                        <AppPagination setPage={setPage} pageCount={pageCount} />
                        <CustomerMenu sx={{ minWidth: 250 }} customers={customers} tiers={tiers} prevSelCustomer={prevSelCustomer} selectedCustomer={selectedCustomer}
                            updateSelectedCustomer={updateSelectedCustomer} />
                        {/* <AppPagination setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} pageCount={pageCount} /> */}
                    </Box>
                    <Box sx={{
                        minWidth: 500,
                        width: '70%',
                        overflowY: "scroll"
                    }}>
                        {selectedCustomer ?
                            <RewardDetail customer={selectedCustomer} tiers={tiers} />
                            : <Box></Box>
                        }
                    </Box>
                </Box >
            }
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
    )
}

export default CustomerDisplay;
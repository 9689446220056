import React, { useEffect } from 'react';
import { Typography, Grid, Box, Divider } from '@mui/material';
import { useBaseStyles } from '../../common/styles';
import ActionPopover from './ActionPopover';
import { DisplayPoints } from "./OrderUtil";

function OrderList({ orders, prevSelOrder, selectedOrder, updateSelectedOrder }) {
    const baseClasses = useBaseStyles();

    useEffect(() => {
        if (prevSelOrder) // may not exist, because storeId or search condition changes in admin.spotfraud.app
            document.querySelector(`#order_${prevSelOrder.id}`)?.classList.remove('selected');
        if (selectedOrder)
            document.querySelector(`#order_${selectedOrder.id}`).classList.add('selected');
    }, [selectedOrder]);

    const handleClick = (e, newOrder) => {
        e.preventDefault();
        if (selectedOrder.id !== newOrder.id) {
            updateSelectedOrder(newOrder);
        }
    }

    return (
        <Box sx={{
            backgroundColor: 'white',
            padding: 1
        }}>
            <Divider sx={{ mb: 1 }} />
            <Grid container direction="row"
                sx={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontWeight: 600,
                    fontSize: 14,
                }}
                className='item'
            >
                <Grid item key={1} xs={4} sm={2} md={1}>
                    Order
                </Grid>

                <Grid item key={2} xs={4} sm={2} md={1.2}>
                    Date
                </Grid>

                <Grid item key={3} xs={4} sm={2} md={1.5}>
                    Customer
                </Grid>

                <Grid item key={4} xs={4} sm={2} md={1.1} >
                    Subtotal
                </Grid>

                <Grid item key={5} xs={4} sm={2} md={1}>
                    Discount
                </Grid>

                <Grid item key={6} xs={4} sm={2} md={1} >
                    Credit
                </Grid>

                <Grid item key={7} xs={4} sm={2} md={1} >
                    Total
                </Grid>

                <Grid item key={8} xs={4} sm={2} md={1.2}>
                    Status
                </Grid>

                <Grid item key={9} xs={4} sm={2} md={1}>
                    Points
                </Grid>

                <Grid item key={10} xs={4} sm={2} md={1.2}>
                    Process Date
                </Grid>

                <Grid item key={11} xs={4} sm={2} md={0.8}>
                    Action
                </Grid>
            </Grid>
            <Divider sx={{ mt: 1 }} />
            {orders.length === 0 ?
                <Typography>No Orders</Typography> :
                orders.map((order, index) => (
                    <div key={index} >
                        <Grid container direction="row"
                            sx={{
                                alignItems: "center",
                                fontSize: 13,
                            }}
                            key={index} className='item' id={`order_${order.id}`}
                            onClick={(e) => handleClick(e, order)}
                        >
                            {/* <Grid item key={1} xs={4} className={baseClasses.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 9 }}>
                                    {order.abbrevStoreName || abbrevStoreName}
                                </Typography>
                            </Grid> */}

                            <Grid item key={1} xs={4} sm={2} md={1}>
                                {order.id}
                            </Grid>
                            <Grid item key={2} xs={4} sm={2} md={1.2}>
                                {order.date}
                            </Grid>
                            <Grid item key={3} xs={4} sm={2} md={1.5}>
                                {order.first_name} {order.last_name}
                            </Grid>

                            <Grid item key={4} xs={4} sm={2} md={1.1} >
                                {order.currency_symbol}{order.subtotal_ex_tax}
                            </Grid>

                            <Grid item key={5} xs={4} sm={2} md={1} className={order.discount > 0 ?
                                baseClasses.error : baseClasses.secondary}>
                                {order.discount > 0 ? `-${order.currency_symbol}${order.discount}` : order.discount}
                            </Grid>

                            <Grid item key={6} xs={4} sm={2} md={1} className={order.store_credit_amount > 0 ?
                                baseClasses.error : baseClasses.secondary}>
                                {order.store_credit_amount > 0 ? `-${order.currency_symbol}${order.store_credit_amount}` : order.store_credit_amount}
                            </Grid>

                            <Grid item key={7} xs={4} sm={2} md={1} >
                                {order.currency_symbol}{order.subtotal_ex_tax_discount_credit}
                            </Grid>

                            <Grid item key={8} xs={4} sm={2} md={1.2}>
                                {order.status}
                            </Grid>

                            <Grid item key={9} xs={4} sm={2} md={1}>
                                <DisplayPoints order={order} />
                            </Grid>

                            <Grid item key={10} xs={4} sm={2} md={1.2}>
                                {order.post_points_date}
                            </Grid>


                            <Grid item key={11} xs={4} sm={2} md={0.8}>
                                <ActionPopover order={order} />
                            </Grid>
                        </Grid>
                        <Divider />
                    </div>
                ))
            }
        </Box >
    );
}

export default React.memo(OrderList);


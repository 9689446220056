const OrderService = {
    getOrders: function (axiosPrivate, page, pageSize) {
        return axiosPrivate.get(`/api/v1/orders?page=${page}&pageSize=${pageSize}`);
    },
    searchOrders: function (axiosPrivate, filter, page, pageSize) {
        return axiosPrivate.get(`/api/v1/orders/search?${filter}&page=${page}&pageSize=${pageSize}`);
    }
}

export default OrderService;
//java static class
import React from 'react';
import { Box, IconButton, Popover, ListItem, ListItemButton, ListItemText } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useAppContext from '../../common/hooks/useAppContext';
import { disabledRoles } from '../../common/data';

function OrderActionPopover({ order }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { auth } = useAppContext();
    const currentTimeStamp = new Date().getTime();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const postPoints = (e, order) => {
        e.preventDefault();

    }

    return (
        <Box>
            <IconButton
                onClick={handleClick}
                disabled={disabledRoles.includes(auth.user.role) ||
                    order.points_status === "posted" ||
                    (order.points_status === "initial" && currentTimeStamp > order.post_points_epoch_ms)}
            >
                <MoreHorizIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ListItem
                    disablePadding
                >
                    <ListItemButton
                        sx={{
                            justifyContent: 'center',
                            px: 1
                        }}
                        onClick={(e) => { postPoints(e, order) }}
                    >
                        <ListItemText
                            disableTypography={true}
                            sx={{ fontFamily: "Slabien", fontSize: 14 }}
                            primary="Post"
                        />
                    </ListItemButton>
                </ListItem>
            </Popover>
        </Box >
    );
}

export default React.memo(OrderActionPopover);


import React from 'react';
import OrderDisplay from '../../components/orders/OrderDisplay';
import OrderService from '../../services/OrderService';

const Orders = () => {
    return (
        <OrderDisplay ordersFunct={OrderService.getOrders} />
    );
}

export default Orders;
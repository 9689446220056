const CustomerService = {
    getCustomers: function (axiosPrivate, page, pageSize) {
        return axiosPrivate.get(`/api/v1/customers?page=${page}&pageSize=${pageSize}`);
    },
    searchCustomers: function (axiosPrivate, filter, page, pageSize) {
        return axiosPrivate.get(`/api/v1/customers/search?${filter}&page=${page}&pageSize=${pageSize}`);
    }
}

export default CustomerService;
//java static class
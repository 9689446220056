import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, TextField, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Notification } from '../common/components/common';
import { SearchButton } from "../common/components/common/inputs";

export default function SearchBar() {
    const [text, setText] = useState(''); //  the phrase to be searched
    const navigate = useNavigate();
    const location = useLocation();
    const pathName = location.pathname;
    const placeholder = "Email, Order NO (Orders Search only)";
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });

    const StyledButton = styled(SearchButton)(({ theme }) => ({
    }))

    const handleChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        console.log(value);
        setText(value);
    };

    const handleKeyDown = (e) => {
        // e.preventDefault(); //cannot use preventDefault, otherwise cannot accept input
        if (e.key === 'Enter') {
            handleSearch(e);
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if (text === '')
            return;

        if (text.includes('@')) {
            navigate(`/orders?email=${text}`)
        } else {
            navigate(`/orders?id=${text}`)
        }

    }

    const handleCustomerSearch = (e) => {
        if (text === '')
            return;

        if (text.includes('@')) {
            navigate(`/customers?email=${text}`)
        } else {
            setNotify({
                isOpen: true,
                message: 'Please input Email',
                type: 'error'
            })
        }

    }

    const handleClear = () => {
        setText("");
        navigate(pathName);
    };

    return (
        <Box sx={{
            display: "flex",
            marginLeft: 7
        }}>
            <TextField
                type='text'
                size="small"
                variant='outlined'
                name="text"
                value={text}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                sx={{
                    minWidth: 600,
                    bgcolor: 'white',
                    borderRadius: 1
                }}

                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                sx={{
                                    visibility: text ? "visible" : "hidden",
                                    margin: 0
                                }}
                                onClick={handleClear}
                            >
                                <ClearIcon />
                            </IconButton>
                            <StyledButton
                                text="Orders"
                                color="grey"
                                size="medium"
                                onClick={handleSearch} />

                            <StyledButton
                                //following can override only in styled indirectly instead of in SearchBar directly
                                sx={{
                                    marginRight: -1.6
                                }}
                                text="Customers"
                                color="grey"
                                size="medium"
                                onClick={handleCustomerSearch} />
                        </InputAdornment>
                    ),
                }}
            />
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </Box>
    )
}